import { WithWidth } from '@material-ui/core/withWidth';
import { useTranslation } from '../../hooks/useTranslation';
import React from 'react';
import AuthLayout from '../../components/auth-layout';
import { usePlannedDownTimeRedirect } from '../../hooks/usePlannedDownTimeRedirect';
import Helmet from 'react-helmet';
import useQuickLogin from '../../hooks/useQuickLogin';
import QuickLoginForm from '../../components/quick-login-form/QuickLoginForm';
import QuickLoginStatusMessage from '../../components/quick-login-form/QuickLoginStatusMessage';
import Backdrop from '../../components/backdrop';


interface QuickLoginPageProps extends WithWidth {
  path: string;
}

const QuickLoginPage = (props: QuickLoginPageProps) => {
  usePlannedDownTimeRedirect();

  const { t } = useTranslation();
  const title = t('ONE_TIME_LOGIN_PAGE_TITLE');
  const isMobile = ['xs', 'sm'].includes(props.width);
  const {
    quickform,
    token,
    oneTimeTokenLoading,
    quickLoginhandleSubmit,
    successMessage,
    handleBack,
    tokenStatus
} = useQuickLogin({});
    
  if (token && !tokenStatus) {
    return <Backdrop forceOpen message={t('STILL_LOADING')} />;
  }

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <AuthLayout title={title} subtitle={t('ONE_TIME_LOGIN_SUB_TITLE')} showLeft={false}>        
        {!oneTimeTokenLoading && token  && tokenStatus && <QuickLoginStatusMessage message={t('ONE_TIME_LOGIN_TOKEN_EXPIRED')} action='error'></QuickLoginStatusMessage>}
        {!token && <QuickLoginForm handleBack={handleBack} form={quickform} handleSubmit={quickLoginhandleSubmit} successMessage={successMessage} />}
      </AuthLayout>
    </>
  );
};

export default QuickLoginPage;
