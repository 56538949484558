import React, { FunctionComponent, useContext } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import Paper from '@material-ui/core/Paper';
import CardContent from '@material-ui/core/CardContent';
import { useTranslation } from '../../hooks/useTranslation';
import { Grid } from '@material-ui/core';
import Alerts from '../alerts';
import { AlertSeverity } from '../alerts/Alerts';
import Link from '../text-link';
import ROUTES from '../../routes';

const useStyles = makeStyles((theme: Theme) => ({
  card: {
    padding: '1em 1em 0 1em',
    marginTop:'1em',
  },
  cardContent: {
    display: 'flex',
    flexDirection: 'column',
    padding: 0,
  },
  messageBox: {
    marginTop: '1em',
    '& > .MuiCollapse-container > .MuiCollapse-wrapper': {
      '& .MuiCollapse-wrapperInner > .MuiAlert-standardSuccess': {
        backgroundColor: '#fff !important',
      },
    },
  },
}));

export interface QuickLoginStatusMessageProps {
    message: string;
    action: AlertSeverity;
    handleBack?: () => void;
  }


const QuickLoginStatusMessage: FunctionComponent<QuickLoginStatusMessageProps> = (
  props: QuickLoginStatusMessageProps,
) => {
  const classes = useStyles(props);
  const { t } = useTranslation();
 
  return (
    <>
      <div style={{ justifyContent: 'flex-start' }}>
            <Grid item xs={12} className={classes.messageBox}>
              <Alerts
                isOpen
                severity={props.action}
                variant={'standard'}
                message={
                  <Typography
                    style={{ marginTop: '-8px' }}
                  >
                    {props.message}
                  </Typography>
                }
              />             
            </Grid> 
      </div>
    </>
  );
};

export default QuickLoginStatusMessage;
